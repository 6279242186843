import {
  ref, reactive, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function useProjectView() {
  const resolveUserProjectStatus = status => {
    if (status === 'danger') return 'Terminado'
    if (status === 'success') return 'En proceso'

    return 'No especificado'
  }

  const projectData = computed(() => store.state.project.data)
  const fetchProject = async params => {
      await store.dispatch('model/project/fetchProject', params)
          .then(response => {
              store.commit('project/setData', response.data)
          })
          .catch(error => {
              if (error.response.status === 404) {
                  store.commit('project/setData', null)
              }
          })
  }

  const projectActivityData = computed(() => store.state.project.activity)

  const fetchProjectActivity = async params => {
    await store.dispatch('model/project/fetchProjectActivity', params)
      .then(response => {
        store.commit('project/setActivity', response.data)
      })
      .catch(error => {
        if (error.status === 404) {
          store.commit('project/setActivity', null)
        }
      })
  }

  const updateMaintenanceTasksOrder = async params => {
      await store.dispatch('model/maintenance/updateMaintenanceTasksOrder', params)
          .then(response => true,
              // store.commit('project/updateData', response.data)
          )
          .catch(error => {
              if (error.response.status === 404) {
                  //
              }
          })
  }

  const updateProject = async params => {
      await store.dispatch('model/project/updateProject', params)
          .then(response => {
              store.commit('project/updateData', response.data)
              fetchProjectActivity(params)
          })
          .catch(error => {
              if (error.response.status === 404) {
                  //
              }
          })
  }

  const projectResponsibleData = computed(() => store.state.project.responsible)

  const fetchProjectResponsible = async params => {
      await store.dispatch('model/project/fetchProjectResponsible', params)
          .then(response => {
              store.commit('project/setResponsible', response.data)
          })
          .catch(error => {
              if (error.response.status === 404) {
                  store.commit('project/setResponsible', null)
              }
          })
  }

  const projectUsersData = computed(() => store.state.project.users)

  const fetchProjectUsers = async params => {
      await store.dispatch('model/project/fetchProjectUsers', params)
      .then(response => {
          store.commit('project/setUsers', response.data)
      })
      .catch(error => {
        if (error.response.status === 404) {
            store.commit('project/setUsers', null)
        }
      })
    }

  const addUserProject = async params => {
      store.dispatch('model/project/addProjectUsers', params)
          .then(response => {
              fetchProjectUsers(params)
              fetchProjectActivity(params)
          })
          .catch(error => {
              if (error.response.status === 404) {
                  //
              }
          })
  }

  const projectTasksData = computed(() => store.state.project.tasks)

  const fetchProjectTasks = async params => {
      await store.dispatch('model/project/fetchProjectTasks', params)
          .then(response => {
              store.commit('project/setTasks', response.data)
          })
          .catch(error => {
              if (error.response.status === 404) {
                  store.commit('project/setTasks', null)
              }
          })
  }

  const projectMaintenancesData = computed(() => store.state.project.maintenances)

  const fetchProjectMaintenances = async params => {
      await store.dispatch('model/project/fetchProjectMaintenances', params)
          .then(response => {
              store.commit('project/setMaintenances', response.data)
          })
          .catch(error => {
              if (error.response.status === 404) {
                  store.commit('project/setMaintenances', null)
              }
          })
  }

  // FUNCIONES PARA FOTOS
  const projectPhotosData = computed(() => store.state.project.photos)

  const fetchProjectPhotos = async params => {
      await store.dispatch('model/project/fetchProjectPhotos', params)
          .then(response => {
              store.commit('project/setPhotos', response.data)
          })
          .catch(error => {
              if (error.status === 404) {
                  store.commit('project/setPhotos', null)
              }
          })
  }

  const uploadPhotos = async params => {
      await store.dispatch('model/project/uploadPhotos', params)
          .then(response => {
              fetchProjectPhotos(params)
              fetchProjectActivity(params)
          })
          .catch(error => {
              if (error.response.status === 404) {
                  //
              }
          })
  }

  const deletePhoto = async params => {
      await store.dispatch('model/project/deletePhoto', params)
          .then(response => {
              fetchProjectPhotos(params)
              fetchProjectActivity(params)
          })
          .catch(error => {
              if (error.response.status === 404) {
                  //
              }
          })
  }

  const projectDocumentsData = computed(() => store.state.project.documents)

  const fetchProjectDocuments = async params => {
      await store.dispatch('model/project/fetchProjectDocuments', params)
          .then(response => {
              store.commit('project/setDocuments', response.data)
          })
          .catch(error => {
              if (error.status === 404) {
                  store.commit('project/setDocuments', null)
              }
          })
  }

  const uploadDocuments = async params => {
    await store.dispatch('model/project/uploadDocuments', params)
      .then(response => {
        fetchProjectDocuments(params)
        fetchProjectActivity(params)
      })
      .catch(error => {
        if (error.response.status === 404) {
          //
        }
      })
  }

  const deleteDocument = async params => {
    await store.dispatch('model/project/deleteDocument', params)
      .then(response => {
        fetchProjectDocuments(params)
        fetchProjectActivity(params)
      })
      .catch(error => {
        if (error.response.status === 404) {
          //
        }
      })
  }

  const fetchMaterialsSelect = async params => {
    let materials = {}

    await store.dispatch('model/material/fetchMaterialsForSelect', params)
      .then(response => {
        // materials = { 1: 'primero', 2: 'segundo' }
        materials = response.data
        // store.commit('project/setMaterials', response.data)
      })
      .catch(error => {
        if (error.status === 404) {
          store.commit('project/setMaterials', null)
        }
      })

    return materials
  }

  const checkMaterial = params => store.dispatch('model/project/checkMaterial', params)
    .then(response => response)
    .catch(error => {
      if (error.response.status === 404) {
        //
      }
    })

  const taskDone = params => store.dispatch('model/project/taskDone', params)
    .then(response => response)
    .catch(error => {
      if (error.response.status === 404) {
        //
      }
    })

  return {
    fetchProject,
    projectData,
    updateProject,
    fetchProjectResponsible,
    projectResponsibleData,
    fetchProjectUsers,
    projectUsersData,
    addUserProject,
    fetchProjectActivity,
    projectActivityData,
    fetchProjectMaintenances,
    projectMaintenancesData,
    fetchProjectTasks,
    projectTasksData,
    fetchProjectPhotos,
    projectPhotosData,
    uploadPhotos,
    deletePhoto,
    fetchProjectDocuments,
    projectDocumentsData,
    uploadDocuments,
    deleteDocument,
    updateMaintenanceTasksOrder,
    resolveUserProjectStatus,
    fetchMaterialsSelect,
    checkMaterial,
    taskDone,
  }
}
