<template>
  <div :id="`popover-reactive-sidebar_${propType}`">
    <b-avatar-group
      size="32px"
    >
      <transition-group
        tag="div"
        enter-active-class="animated fadeInDown"
        leave-active-class="animated fadeOutDown"
      >
        <b-avatar
          v-for="user in propUsersSelected"
          :key="user.id"
          v-b-tooltip.hover
          class-name="pull-up"
          :title="user.name"
          :src="user.avatar"
        />
      </transition-group>
      <b-avatar
        :id="`popover-reactive-sidebar_${propType}`"
        button
        :variant="addButton.variant"
        :text="addButton.text"
        class="align-baseline ml-1"
      >
        <span class="d-flex align-items-center">
          <feather-icon
            :icon="addButton.icon"
            size="16"
          />
        </span>
      </b-avatar>
    </b-avatar-group>
    <b-popover
      ref="popover-sidebar"
      :target="`popover-reactive-sidebar_${propType}`"
      triggers="click"
      :show.sync="popoverShow"
      placement="auto"
      container="sidebar"
      variant="light"
    >
      <template v-slot:title>
        <div class="d-flex justify-content-between align-items-center">
          <b-form-input
            ref="input-sidebar"
            v-model="search"
            block
            :placeholder="$t('Search')"
          />
        </div>
      </template>

      <div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-row
              v-for="index in 2"
              :key="index"
              align-v="center"
              align-h="start"
              class="mb-1"
            >
              <b-col
                cols="12"
                sm="4"
                md="3"
              >
                <b-skeleton type="avatar" />
              </b-col>
              <b-col
                cols="12"
                sm="8"
                md="9"
              >
                <span class="ml-1"><b-skeleton width="55%" /></span>
              </b-col>
            </b-row>
          </template>
          <b-row
            v-for="user in usersFilter"
            :key="user.id"
            align-h="start"
            class="mb-1"
            v-if="user.status !== 'Suspended' || selected.includes(user.id)"
          >
            <b-col
              cols="12"
              sm="3"
            >
              <b-form-checkbox
                v-model="selected"
                class="custom-control-success mr-1"
                name="check-button"
                switch
                :value="user.id"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="12"
              sm="9"
            >
              <b-avatar
                v-if="compareUser(user.id)"
                v-b-tooltip.hover
                class-name="pull-up"
                :title="user.name"
                :src="user.avatar"
                badge
                :badge-variant="user.status === 'Suspended' ? 'danger' : 'success'"
              />
              <b-avatar
                v-else
                v-b-tooltip.hover
                class-name="pull-up"
                :title="user.name"
                :src="user.avatar"
              />
              <span class="ml-1">{{ user.name }} </span>
            </b-col>
          </b-row>
        </b-skeleton-wrapper>

        <b-button
          v-if="checkDiffSelectedInicio(selected, selectedInicio)"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          :disabled="loading"
          variant="primary"
          @click="onOk"
        >
          <b-spinner
            v-if="loading"
            small
            type="grow"
          />
          {{ $t('Edit to team') }}
        </b-button>
      </div>
    </b-popover>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BSkeleton,
  BSkeletonWrapper,
  BPopover,
  VBPopover,
  BAvatarGroup,
  BAvatar,
  VBTooltip,
  BButton,
  BAlert,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BCard,
  BBadge,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import {
  reactive, ref, computed, onMounted, watch, toRef,
} from "@vue/composition-api"
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
import router from '@/router'
import store from '@/store'
import useUsersList from "@/views/apps/user/users-list/useUsersList"

export default {
  components: {
    BRow,
    BCol,
    BSkeleton,
    BSkeletonWrapper,
    BBadge,
    BAvatarGroup,
    BAvatar,
    BPopover,
    BButton,
    BAlert,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BCard,
    BFormCheckbox,
    BSpinner,
  },
    directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    usersSelected: {
      type: [Array, Object, String],
      required: false,
    },
    type: {
      type: [Text, String],
      required: true,
    },
  },
  setup(props, { emit }) {
    const popoverShow = ref(false)
    const propType = (props.type && props.type !== undefined) ? computed(() => props.type) : 'Users'
    const usersSelected = toRef(props, 'usersSelected')

    const propUsersSelected = ref(usersSelected.value)
    const resetUsersSelected = () => {
      propUsersSelected.value = usersSelected.value
    }
    watch(usersSelected, () => {
      resetUsersSelected()
      buildComponent()
    })

    const {
      users, getUsers,
    } = useUsersList()

    const search = ref('')
    const loading = ref(false)
    const addButton = ref({ text: '+', variant: 'primary', icon: 'EditIcon' })
    const selected = ref([])
    const selectedInicio = ref([])

    const usersFilter = ref([])

    watch(popoverShow, (currentValue, oldValue) => {
          addButton.value.text = (currentValue) ? '-' : '+'
          addButton.value.variant = (currentValue) ? 'secondary' : 'primary'
          addButton.value.icon = (currentValue) ? 'XSquareIcon' : 'EditIcon'
        })

    watch(search, (currentValue, oldValue) => {
          if (currentValue) {
            usersFilter.value = _.filter(users.value, o => ((_.toLower(o.name).search(_.toLower(currentValue)) !== -1)))
          } else {
            usersFilter.value = users.value
          }
        })

    const checkDiffSelectedInicio = (sel, selIni) => !_.isEqual(_.sortBy(sel), _.sortBy(selIni))

    const buildComponent = async () => {
      loading.value = true
      selectedInicio.value = _.map(propUsersSelected.value, 'id')
      selected.value = selectedInicio.value
      usersFilter.value = users.value
      loading.value = false
    }

    const onClose = () => {
      popoverShow.value = false
    }

    const onOk = async () => {
      loading.value = true
      let usersSelectedTemp = _.filter(users.value, o => (_.indexOf(selected.value, o.id) > -1))
      propUsersSelected.value = usersSelectedTemp
      selectedInicio.value = _.map(usersSelectedTemp, 'id')
      emit('update:usersSelected', usersSelectedTemp)
      emit('update:usersSelectedArray', selectedInicio.value)
      popoverShow.value = false
      loading.value = false
    }

    const compareUser = id => (_.findIndex(propUsersSelected.value, { id }) > -1)

    onMounted(async () => {
      await getUsers()
      buildComponent()
    })
    return {
      propType,
      popoverShow,
      users,
      propUsersSelected,
      usersFilter,
      search,
      loading,
      addButton,
      compareUser,
      selected,
      selectedInicio,
      checkDiffSelectedInicio,
      onOk,
      onClose,
    }
  },
}
</script>
<style lang="scss" scoped>
.popover {
  min-width:250px;
  width:100%;
  max-width:350px;
  overflow-wrap:break-word;
}
</style>
