<template>
  <div>
    <b-overlay
      :show="isTaskCompleteOverlayActiveProp"
      no-wrap
    >
      <template v-slot:overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <h4>{{ $t('task.Complete task') }}</h4>
          <p><strong id="form-confirm-label">{{ $t('Are you sure?') }}</strong></p>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOk"
            >
              {{ $t('OK') }}
            </b-button>
          </div>
          <b-overlay
            :show="loading"
            no-wrap
          />
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormRow, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BRow, BCol, BFormDatepicker, BPopover, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  computed, ref, onMounted, onBeforeMount, onUnmounted, watch, toRef,
} from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import _ from 'lodash'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormRow,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormCheckbox,
    BOverlay,
    BPopover,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  model: {
    prop: 'isTaskCompleteOverlayActive',
    event: 'update:is-task-complete-overlay-active',
  },
  props: {
    isTaskCompleteOverlayActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: [Array, Object],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const taskProp = toRef(props, 'task')

    const isTaskCompleteOverlayActiveProp = toRef(props, 'isTaskCompleteOverlayActive')

    const onOk = () => {
      emit('onOk')
      emit('update:is-task-complete-overlay-active', false)
    }

    const onCancel = () => {
      emit('onCancel')
      emit('update:is-task-complete-overlay-active', false)
    }

    return {
      onOk,
      onCancel,
      isTaskCompleteOverlayActiveProp,
      loading,
      taskProp,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
