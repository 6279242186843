import {
  ref, reactive, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function useTaskView() {
    const addTask = params => store.dispatch('model/task/addTask', params)
        .then(response => response)
        .catch(error => {
            if (error.response.status === 404) {
                //
            }
        })

    const editTask = params => store.dispatch('model/task/editTask', params)
        .then(response => response)
        .catch(error => {
            if (error.response.status === 404) {
                //
            }
        })

    const deleteTask = params => store.dispatch('model/task/deleteTask', params)
        .then(response => response)
        .catch(error => {
            if (error.response.status === 404) {
                //
            }
        })


    const addUserTask = async params => store.dispatch('model/task/addTaskUsers', params)
        .then(response => response)
        .catch(error => {
            if (error.response.status === 404) {
                //
            }
        })

    const resolveUserTaskStatus = status => {
        if (status === 'danger') return 'Terminado'
        if (status === 'success') return 'En proceso'

        return 'No especificado'
    }

  return {
    addTask,
    deleteTask,
    editTask,
    addUserTask,
    resolveUserTaskStatus,
  }
}
