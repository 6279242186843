<template>
  <div>
    <task-list-edit
      :task="task"
      :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
      @refetch-data="refetchData"
    />
    <task-list-add-new
      :is-add-new-task-sidebar-active.sync="isAddNewTaskSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("records") }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search') + '...'"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refTaskListTable"
        class="position-relative"
        :items="fetchTasks"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="handleTaskClick(data.item)"
          >
            {{ data.item.name }}
          </b-link>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(expected_start_date)="data">
          {{ dateFormat(data.value) }}
        </template>
        <template #cell(started_at)="data">
          {{ dateFormat(data.value) }}
        </template>
        <template #cell(finished_at)="data">
          {{ dateFormat(data.value) }}
        </template>
        <template #cell(users)="row">
          <users-group-simple
              v-if="row.item.users"
              :type="`task_table_${row.item.id}`"
              :users-selected="row.item.users"
          />
        </template>

        <template #cell(progress)="data">
          <div class="progress-wrapper">
            <b-card-text class="mb-0">
              {{ $t('progress') }} {{ data.value }}%
            </b-card-text>
            <b-progress
              v-model="data.value"
              max="100"
            />
          </div>
        </template>

        <template #cell(users)="row">
          <users-group-simple
            v-if="row.item.users"
            :type="`tasks_table_${row.item.id}`"
            :users-selected="row.item.users"
          />
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('records') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalTasks"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BPagination, BProgress, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useTasksList from './useTasksList'
import TaskListAddNew from './TaskListAddNew.vue'
import TaskListEdit from "@/views/apps/task/tasks-list/TaskListEdit"
import UsersGroupSimple from '@/views/apps/user/UsersGroupSimple.vue'
import useGeneralView from "@/views/useGeneralView"

export default {
  components: {
    TaskListAddNew,
    TaskListEdit,
    UsersGroupSimple,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BProgress,
    BCardText,

    vSelect,
  },
  setup() {
    const isAddNewTaskSidebarActive = ref(false)
    const isTaskHandlerSidebarActive = ref(false)

    const clients = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const task = ref(null)

    const handleTaskClick = taskData => {
      task.value = taskData
      isTaskHandlerSidebarActive.value = true
    }

    const {
      fetchTasks,
      tableColumns,
      perPage,
      currentPage,
      totalTasks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTaskListTable,
      refetchData,

      clientSelect,
    } = useTasksList()

    const { dateFormat } = useGeneralView()

    return {

      // Sidebar
      isAddNewTaskSidebarActive,

      fetchTasks,
      tableColumns,
      perPage,
      currentPage,
      totalTasks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTaskListTable,
      refetchData,

      // Filter
      avatarText,

      clientSelect,
      clients,

      task,
      isTaskHandlerSidebarActive,
      handleTaskClick,
      dateFormat,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
