<template>
  <b-sidebar
    id="add-new-task-sidebar"
    :visible="isAddNewTaskSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="50em"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-task-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t("tasks.Add Task") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- project_id -->
          <validation-provider
            v-if="!propProjectId"
            #default="validationContext"
            name="project_id"
            rules="required"
          >
            <b-form-group
              label="project_id"
              label-for="project_id"
            >
              <b-form-input
                id="client_id"
                v-model="taskData.project_id"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="name"
              label-for="name"
            >
              <template v-slot:label>
                {{ $t("tasks.task name") }} <code>*</code>
              </template>
              <b-form-input
                id="name"
                v-model="taskData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

            </b-form-group>
          </validation-provider>

          <!-- comments -->
          <b-form-group
            :label="$t('comment')"
            label-for="comments"
          >
            <b-form-textarea
              id="comments"
              v-model="taskData.comments"
              autofocus
              trim
              placeholder=""
            />
          </b-form-group>

          <b-form-row>
            <b-col
              cols="12"
              lg="4"
            >
              <!-- expected_start_date -->
              <validation-provider
                #default="validationContext"
                name="expected_start_date"
              >
                <b-form-group
                  :label="$t('tasks.expected_start_date')"
                  label-for="expected_start_date"
                >
                  <b-form-datepicker
                    v-model="taskData.expected_start_date"
                    :initial-date="taskData.expected_start_date"
                    aria-placeholder="DD/MM/YYY"
                    left
                    calendar-width="100%"
                    locale="es-ES"
                    start-weekday="1"
                    placeholder=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :state="getValidationState(validationContext)"
                  />

                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- real_start_date -->
            <b-col
              cols="12"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                name="started_at"
              >
                <b-form-group
                  :label="$t('tasks.started_at')"
                  label-for="started_at"
                >
                  <b-form-datepicker
                    v-model="taskData.started_at"
                    :initial-date="taskData.started_at"
                    aria-placeholder="DD/MM/YYY"
                    left
                    calendar-width="100%"
                    locale="es-ES"
                    start-weekday="1"
                    placeholder=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- finish_date -->
            <b-col
              cols="12"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                name="finished_at"
              >
                <b-form-group
                  :label="$t('tasks.finished_at')"
                  label-for="started_at"
                >
                  <b-form-datepicker
                    v-model="taskData.finished_at"
                    :initial-date="taskData.finished_at"
                    aria-placeholder="DD/MM/YYY"
                    left
                    calendar-width="100%"
                    locale="es-ES"
                    start-weekday="1"
                    placeholder=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-form-row>

          <b-form-row>
            <!-- budget -->
            <b-col
              cols="12"
              lg="3"
            >
              <b-form-group
                :label="$t('tasks.budget')"
                label-for="budget"
              >
                <cleave
                  id="budget"
                  v-model="taskData.budget"
                  class="form-control"
                  autofocus
                  trim
                  :options="options.number"
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <!-- Progress -->
            <b-col
              cols="12"
              lg="8"
            >
              <b-form-group
                :label="$t('tasks.progress') + ' ' + taskData.progress + '%'"
                label-for="budget"
                class="ml-1"
              >
                <vue-slider
                  v-model="taskData.progress"
                  :min="options.sliderMin"
                  :max="options.sliderMax"
                  :direction="options.sliderDirection"
                  :interval="options.sliderInterval"
                  class="mb-2 mt-1 vue-slider-primary"
                />
              </b-form-group>
            </b-col>
          </b-form-row>

          <div>
            <b-row>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
            <h4>
              {{ $t('team') }}
            </h4>
            <tasks-users-group
              type="Task"
              :users-selected.sync="taskData.users"
              :users-selected-array.sync="usersTaskSelected"
            />
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BRow, BCol, BForm, BFormRow, BFormGroup, BFormInput, BFormDatepicker, BFormInvalidFeedback, BButton, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { computed, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import VueSlider from 'vue-slider-component'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import TasksUsersGroup from '@/views/apps/user/UsersGroup.vue'
import useTaskView from "@/views/apps/task/tasks-view/useTaskView"
import useProjectView from "@/views/apps/project/projects-view/useProjectView"
import useGeneralView from "@/views/useGeneralView"

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BForm,
    BFormRow,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    Cleave,
    VueSlider,
    TasksUsersGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewTaskSidebarActive',
    event: 'update:is-add-new-task-sidebar-active',
  },
  props: {
    isAddNewTaskSidebarActive: {
      type: Boolean,
      required: true,
    },
    project_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
          },
        sliderMin: 0,
        sliderMax: 100,
        sliderDirection: 'ltr',
        sliderInterval: 1,
      },
    }
  },
  setup(props, { emit }) {
    const propProjectId = (props.project_id && props.project_id !== undefined) ? computed(() => props.project_id) : ''

    const blankTaskData = {
      project_id: propProjectId.value,
      name: '',
      status: '',
      expected_start_date: '',
      started_at: '',
      finished_at: '',
      progress: 0,
      comments: '',
      budget: '',
      users: [],
    }

    const usersTaskSelected = ref(null)

    const taskData = ref(JSON.parse(JSON.stringify(blankTaskData)))
    const resetTaskData = () => {
      taskData.value = JSON.parse(JSON.stringify(blankTaskData))
    }

    const queryParams = ref(null)

    const { addTask, addUserTask } = useTaskView()
    const { fetchProjectUsers, fetchProjectTasks, fetchProjectActivity } = useProjectView()
    const { fetchNotifications } = useGeneralView()

    const onSubmit = async () => {
      queryParams.value = JSON.parse(JSON.stringify(taskData.value))
      queryParams.value.items = JSON.stringify(queryParams.value.items)
      queryParams.value.users = usersTaskSelected.value

      await addTask(queryParams.value)
          .then(async response => {
            await addUserTask({ project_id: queryParams.value.project_id, id: response.data.id, users: queryParams.value.users })

            fetchNotifications()

            if (store.hasModule('project')) {
              fetchProjectTasks({ id: queryParams.value.project_id })
              fetchProjectUsers({ id: queryParams.value.project_id })
              fetchProjectActivity({ id: queryParams.value.project_id })
            }
            emit('refetch-data')
            emit('update:is-add-new-task-sidebar-active', false)
            // router.push({ name: 'maintenances-edit', params: { id: maintenanceId } })
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetTaskData)

    return {
      propProjectId,
      taskData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      usersTaskSelected,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';

#add-new-task-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
