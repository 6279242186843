<template>
  <div :id="`popover-reactive-sidebar_${propType}`">
    <b-avatar-group
      size="32px"
    >
      <transition-group
        tag="div"
        enter-active-class="animated fadeInDown"
        leave-active-class="animated fadeOutDown"
      >
        <b-avatar
          v-for="user in propUsersSelected"
          :key="user.id"
          v-b-tooltip.hover
          class-name="pull-up"
          :title="user.name"
          :src="user.avatar"
        />
      </transition-group>
    </b-avatar-group>
  </div>
</template>

<script>
import {
  BAvatarGroup,
  BAvatar,
  VBTooltip,
} from 'bootstrap-vue'
import {
 ref, computed,
} from "@vue/composition-api"
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAvatarGroup,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    usersSelected: {
      type: [Array, Object, String],
      required: false,
    },
    type: {
      type: [Text, String],
      required: true,
    },
  },
  setup(props) {
    const propType = (props.type && props.type !== undefined) ? computed(() => props.type) : 'Users'
    const propUsersSelected = computed(() => props.usersSelected)

    return {
      propType,
      propUsersSelected,
    }
  },
}
</script>
<style lang="scss" scoped>
.b-avatar-group {
  min-width:100px;
}
</style>
