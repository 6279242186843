import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

export default function useTasksList() {
  // Use toast
  const toast = useToast()

  const refTaskListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true, label: i18n.t('tasks.task') },
    { key: 'status', sortable: true, label: i18n.t("tasks.status") },
    { key: 'expected_start_date', label: i18n.t("tasks.expected_start_date"), sortable: true },
    { key: 'started_at', label: i18n.t("tasks.started_at"), sortable: true },
    { key: 'finished_at', label: i18n.t("tasks.finished_at"), sortable: true },
    { key: 'active', label: i18n.t("tasks.active"), sortable: true },
    { key: 'progress', label: i18n.t("tasks.progress"), tdClass: 'text-right', sortable: true },
    { key: 'users', label: i18n.t('team'), sortable: false },
  ]
  const perPage = ref(10)
  const totalTasks = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refTaskListTable.value ? refTaskListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTasks.value,
    }
  })

  const refetchData = () => {
    refTaskListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchTasks = (ctx, callback) => {
    store
      .dispatch('model/task/fetchTasks', {
        search: searchQuery.value,
        pageSize: perPage.value,
        page: currentPage.value,
        order: sortBy.value,
        dir: (isSortDirDesc.value) ? 'DESC' : 'ASC',
      })
      .then(response => {
        const { records, totalRecords } = response.data

        callback(records)
        totalTasks.value = totalRecords
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Tasks list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchTasks,
    tableColumns,
    perPage,
    currentPage,
    totalTasks,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTaskListTable,

    refetchData,
  }
}
