<template>
  <div>
    <b-overlay
      :show="loading"
      no-wrap
    />
    <b-sidebar
      id="add-new-task-sidebar"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      width="50em"
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ $t("tasks.Edit Task") }}
          </h5>

          <div>
            <b-button
              v-if="(taskData.status === 'Done')"
              ref="button"
              variant="outline-success"
              class="mr-1"
            >
              <feather-icon
                v-show="taskData.id"
                icon="CheckSquareIcon"
                class="cursor-pointer"
              /> {{ $t('Completed') }}
            </b-button>
            <b-button
              v-else
              id="complete-task-button"
              ref="button"
              variant="outline-primary"
              class="mr-1"
              @click="onCompleteTask"
            >
              <feather-icon
                v-show="taskData.id"
                icon="CheckSquareIcon"
                class="cursor-pointer"
              /> {{ $t('Finish') }}
            </b-button>
            <b-button
              id="popover-delete-task"
              ref="button"
              variant="outline-danger"
            >
              <feather-icon
                v-show="taskData.id"
                icon="TrashIcon"
                class="cursor-pointer text-danger"
              /> {{ $t('delete') }}
            </b-button>
            <b-popover
              ref="popover"
              target="popover-delete-task"
              triggers="click"
              placement="left"
              container="popover-delete-task"
            >
              <div>
                <p>{{ $t('Are you sure?') }}</p>
                <b-button
                  size="sm"
                  variant="link"
                  class="mr-1"
                >
                  {{ $t('Cancel') }}
                </b-button>
                <b-button
                  size="sm"
                  variant="danger"
                  @click="onDelete(); hide();"
                >
                  {{ $t('Delete') }}
                </b-button>
              </div>
            </b-popover>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- project_id -->
            <validation-provider
              v-if="(!taskData.project_id && !propProjectId)"
              #default="validationContext"
              name="project_id"
              rules="required"
            >
              <b-form-group
                label="project_id"
                label-for="project_id"
              >
                <b-form-input
                  id="client_id"
                  v-model="taskData.project_id"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

              </b-form-group>
            </validation-provider>

            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="name"
                label-for="name"
              >
                <template v-slot:label>
                  {{ $t("tasks.task name") }} <code>*</code>
                </template>
                <b-form-input
                  id="name"
                  v-model="taskData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

              </b-form-group>
            </validation-provider>

            <!-- comments -->
            <b-form-group
              :label="$t('comment')"
              label-for="comments"
            >
              <b-form-textarea
                id="comments"
                v-model="taskData.comments"
                autofocus
                trim
                placeholder=""
              />
            </b-form-group>

            <b-form-row>
              <b-col
                cols="12"
                lg="4"
              >
                <!-- expected_start_date -->
                <validation-provider
                  #default="validationContext"
                  name="expected_start_date"
                >
                  <b-form-group
                    :label="$t('tasks.expected_start_date')"
                    label-for="expected_start_date"
                  >
                    <b-form-datepicker
                      v-model="taskData.expected_start_date"
                      :initial-date="taskData.expected_start_date"
                      aria-placeholder="DD/MM/YYY"
                      left
                      calendar-width="100%"
                      locale="es-ES"
                      start-weekday="1"
                      placeholder=""
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      :state="getValidationState(validationContext)"
                    />

                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- real_start_date -->
              <b-col
                cols="12"
                lg="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="started_at"
                >
                  <b-form-group
                    :label="$t('tasks.started_at')"
                    label-for="started_at"
                  >
                    <b-form-datepicker
                      v-model="taskData.started_at"
                      :initial-date="taskData.started_at"
                      aria-placeholder="DD/MM/YYY"
                      left
                      calendar-width="100%"
                      locale="es-ES"
                      start-weekday="1"
                      placeholder=""
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- finish_date -->
              <b-col
                cols="12"
                lg="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="finished_at"
                >
                  <b-form-group
                    :label="$t('tasks.finished_at')"
                    label-for="started_at"
                  >
                    <b-form-datepicker
                      v-model="taskData.finished_at"
                      :initial-date="taskData.finished_at"
                      aria-placeholder="DD/MM/YYY"
                      left
                      calendar-width="100%"
                      locale="es-ES"
                      start-weekday="1"
                      placeholder=""
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-form-row>

            <b-form-row>
              <!-- budget -->
              <b-col
                cols="12"
                lg="3"
              >
                <b-form-group
                  :label="$t('tasks.budget')"
                  label-for="budget"
                >
                  <cleave
                    id="budget"
                    v-model="taskData.budget"
                    class="form-control"
                    autofocus
                    trim
                    :options="options.number"
                    placeholder=""
                  />
                </b-form-group>
              </b-col>

              <!-- Progress -->
              <b-col
                cols="12"
                lg="8"
              >
                <b-form-group
                  :label="$t('tasks.progress') + ' ' + taskData.progress + '%'"
                  label-for="budget"
                  class="ml-1"
                >
                  <vue-slider
                    v-model="taskData.progress"
                    :min="options.sliderMin"
                    :max="options.sliderMax"
                    :direction="options.sliderDirection"
                    :interval="options.sliderInterval"
                    class="mb-2 mt-1 vue-slider-primary"
                  />
                </b-form-group>
              </b-col>
            </b-form-row>

            <div>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
              <h4>
                {{ $t('team') }}
              </h4>
              <tasks-users-group
                type="TaskEdit"
                :users-selected.sync="taskData.users"
                :users-selected-array.sync="usersTaskSelected"
              />
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t("Save") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t("Cancel") }}
              </b-button>
            </div>
            <div class="mt-1">
              <i class="color-grey" v-if="(taskData.status === 'Done')">* {{ $t('Task already finished') }}</i>
            </div>
          </b-form>
        </validation-observer>

        <task-complete-overlay
          :task="taskData"
          :is-task-complete-overlay-active.sync="isTaskCompleteOverlayActive"
          @onOk="onFinishTask"
        />

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BRow, BCol, BForm, BFormRow, BFormGroup, BFormInput, BFormDatepicker, BFormInvalidFeedback, BButton, BFormTextarea, BOverlay, BPopover,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
 computed, ref, onUpdated, toRefs, watch,
} from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import VueSlider from 'vue-slider-component'
import _ from "lodash"
import { add, format, parse } from "date-fns"
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import TasksUsersGroup from '@/views/apps/user/UsersGroup.vue'
import useTaskView from "@/views/apps/task/tasks-view/useTaskView"
import useProjectView from "@/views/apps/project/projects-view/useProjectView"
import useGeneralView from "@/views/useGeneralView"
import TaskCompleteOverlay from '@/views/apps/task/tasks-list/TaskCompleteOverlay.vue'

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BForm,
    BFormRow,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormInvalidFeedback,
    BPopover,
    BButton,
    BFormTextarea,
    BOverlay,
    vSelect,
    Cleave,
    VueSlider,
    TasksUsersGroup,
    TaskCompleteOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    project_id: {
      type: Number,
      required: false,
    },
    task: {
      type: [Array, Object],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
        sliderMin: 0,
        sliderMax: 100,
        sliderDirection: 'ltr',
        sliderInterval: 1,
      },
    }
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const propProjectId = (props.project_id && props.project_id !== undefined) ? computed(() => props.project_id) : ''

    const blankTaskData = {
      project_id: propProjectId.value,
      name: '',
      status: '',
      expected_start_date: '',
      started_at: '',
      finished_at: '',
      progress: 0,
      comments: '',
      budget: '',
      users: [],
    }

    const usersTaskSelected = ref(null)
    const taskProp = toRefs(props).task

    const isTaskCompleteOverlayActive = ref(false)
    const isCompleteBtnActive = ref(false)

    const taskData = ref(taskProp.value ? taskProp.value : blankTaskData)
    const taskRenewData = ref(blankTaskData)

    const resetTaskData = () => {
      taskData.value = taskProp.value
    }

    const { editTask, addUserTask, deleteTask } = useTaskView()
    const { fetchProjectUsers, fetchProjectTasks, fetchProjectActivity } = useProjectView()
    const { fetchNotifications } = useGeneralView()

    watch(taskData, newValue => {
      taskRenewData.value = newValue
    })

    onUpdated(() => {
      resetTaskData()
    })

    const onCompleteTask = async () => {
      isCompleteBtnActive.value = true
      isTaskCompleteOverlayActive.value = true
    }

    const onFinishTask = async () => {
      loading.value = true

      // update task done => now   *No pasar usuarios
      const queryParams = _.clone(taskData.value)
      queryParams.done = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
      queryParams.progress = 100
      delete queryParams.users
      await editTask(queryParams).then(async response => {
        taskData.value.done = response.data.done
        fetchNotifications()

        if (store.hasModule('project')) {
          fetchProjectTasks({ id: queryParams.project_id })
          fetchProjectActivity({ id: queryParams.project_id })
        }
      })

      loading.value = false

      emit('refetch-data')
      emit('update:is-task-handler-sidebar-active', false)
    }

    const onSubmit = async () => {
      loading.value = true
      const queryParams = _.clone(taskData.value)
      queryParams.users = usersTaskSelected.value
      await editTask(queryParams)
          .then(async response => {
            await addUserTask({ project_id: queryParams.project_id, id: response.data.id, users: queryParams.users })

            fetchNotifications()

            if (store.hasModule('project')) {
              fetchProjectTasks({ id: queryParams.project_id })
              fetchProjectUsers({ id: queryParams.project_id })
              fetchProjectActivity({ id: queryParams.project_id })
            }
            emit('refetch-data')
            emit('update:is-task-handler-sidebar-active', false)
          })
      loading.value = false
    }

    const onDelete = async () => {
      const queryParams = { id: taskData.value.id, project_id: taskData.value.project_id }
      await deleteTask(queryParams)
          .then(async response => {
            fetchNotifications()

            if (store.hasModule('project')) {
              fetchProjectTasks({ id: queryParams.project_id })
              fetchProjectUsers({ id: queryParams.project_id })
              fetchProjectActivity({ id: queryParams.project_id })
            }
            emit('refetch-data')
            emit('update:is-task-handler-sidebar-active', false)
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(taskData.value)

    return {
      propProjectId,
      taskData,
      onSubmit,
      onDelete,
      isTaskCompleteOverlayActive,
      onCompleteTask,
      onFinishTask,

      refFormObserver,
      getValidationState,
      resetForm,
      usersTaskSelected,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';

#add-new-task-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
